import { createSlice } from "@reduxjs/toolkit";

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: [],
  reducers: {
    enqueueSnackbar: (state, action) => {
      const { message, options } = action.payload;
      state.push({
        key: new Date().getTime() + Math.random(),
        message: message instanceof Error ? message.message : message,
        options: {
          ...options,
          variant: options.variant || "default",
        },
      });
    },
    closeSnackbar: (state, action) => {
      return state.map((notification) =>
        action.payload.dismissAll || notification.key === action.payload.key
          ? { ...notification, dismissed: true }
          : { ...notification },
      );
    },
    removeSnackbar: (state, action) => {
      return state.filter(
        (notification) => notification.key !== action.payload,
      );
    },
  },
});

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;

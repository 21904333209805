import { createBrowserRouter, Outlet } from "react-router-dom";

import { ProtectedRoute } from "./providers/AuthProvider";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import Reset from "./components/Reset";
import Account from "./components/Account";
import Accounts from "./components/Accounts";
import Profile from "./components/Profile";
import Unauthorized from "./components/Unauthorized";
import AppBar from "./components/AppBar";

const Root = () => (
  <>
    <AppBar />
    <Outlet />
  </>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/reset",
    element: <Reset />,
  },
  {
    path: "/unauthorized",
    element: <Unauthorized />,
  },
  {
    path: "/*",
    element: <Root />,

    children: [
      {
        path: "account",
        element: (
          <ProtectedRoute>
            <Account />
          </ProtectedRoute>
        ),
      },
      {
        path: "accounts",
        element: (
          <ProtectedRoute allowedRoles={["admin"]}>
            <Accounts />
          </ProtectedRoute>
        ),
      },
      {
        path: "profile",
        element: (
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

export default router;

import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  ListItemIcon,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { useDispatch, useSelector } from "react-redux";
import { deleteAccount, queryAccounts } from "./../slices/accounts";
import AccountDialog from "./AccountDialog";

function AccountsComponent() {
  const dispatch = useDispatch();
  const { accounts, loading, error } = useSelector((state) => state.accounts);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    dispatch(queryAccounts());
  }, [dispatch]);

  const handleDeleteAccount = (id) => {
    dispatch(deleteAccount(id));
  };

  const handleEditAccount = (id) => {
    setEditId(id);
    setDialogOpen(true);
  };

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setEditId(null);
  };

  const formatBalance = (cents) => {
    return (cents / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Box sx={{ padding: 2, width: "100%", maxWidth: 480, mx: "auto" }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{ fontSize: "1.8rem", mb: 3 }}
      >
        Accounts
      </Typography>
      <Button
        onClick={handleOpenDialog}
        variant="contained"
        color="primary"
        sx={{
          marginBottom: 2,
          width: "100%",
          fontSize: "1rem",
          padding: "10px 20px",
          textTransform: "none",
        }}
      >
        Add New Account
      </Button>
      <Card elevation={1} sx={{ borderRadius: 4, overflow: "hidden" }}>
        <CardContent sx={{ padding: 0 }}>
          <List sx={{ padding: 0 }}>
            {accounts.map((account, index) => (
              <React.Fragment key={account.id}>
                <ListItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "nowrap",
                    padding: 2,
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 35 }}>
                    {account.linked ? (
                      <LinkIcon color="primary" />
                    ) : (
                      <LinkOffIcon />
                    )}
                  </ListItemIcon>
                  <Box sx={{ flex: "1 1 auto", minWidth: 0, marginRight: 1 }}>
                    <Typography
                      variant="subtitle1"
                      noWrap
                      sx={{ fontSize: "1.1rem" }}
                    >
                      {`${account.houseNumber} ${account.propertyAddress}`}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" noWrap>
                      Balance: {formatBalance(account.accountBalance)}
                    </Typography>
                  </Box>
                  <ListItemSecondaryAction
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => handleEditAccount(account.id)}
                      sx={{
                        padding: "6px",
                      }}
                    >
                      <CreateIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteAccount(account.id)}
                      sx={{
                        padding: "6px",
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {index < accounts.length - 1 && <Divider component="li" />}
              </React.Fragment>
            ))}
          </List>
        </CardContent>
      </Card>
      <AccountDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        editId={editId}
      />
    </Box>
  );
}

const Accounts = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        paddingTop: 4,
        paddingBottom: 4,
        bgcolor: "#f5f5f5",
      }}
    >
      <Card
        elevation={0}
        sx={{
          width: "100%",
          maxWidth: 500,
          borderRadius: 3,
          padding: 2,
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          bgcolor: "#ffffff",
        }}
      >
        <AccountsComponent />
      </Card>
    </Box>
  );
};

export default Accounts;

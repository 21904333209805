import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ref, push, set, get, remove, update } from "firebase/database";

import { db } from "./../firebase_config";
import { enqueueSnackbar } from "./notifications"; // Assuming you have this

// Async thunk for adding a new account
export const addAccount = createAsyncThunk(
  "accounts/addAccount",
  async (accountData, { dispatch, rejectWithValue }) => {
    try {
      const newAccountRef = push(ref(db, "accounts"));
      await set(newAccountRef, {
        ...accountData,
        createdAt: Date.now(),
      });
      dispatch(
        enqueueSnackbar({
          message: "Account added successfully",
          options: { variant: "success" },
        }),
      );
      return { id: newAccountRef.key, ...accountData };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "Failed to add account",
          options: { variant: "error" },
        }),
      );
      return rejectWithValue(error.message);
    }
  },
);

// Async thunk for editing an account
export const editAccount = createAsyncThunk(
  "accounts/editAccount",
  async (updates, { dispatch, rejectWithValue }) => {
    try {
      const { id } = updates;
      const accountRef = ref(db, `accounts/${id}`);
      await update(accountRef, updates);
      dispatch(
        enqueueSnackbar({
          message: "Account updated successfully",
          options: { variant: "success" },
        }),
      );
      return { id, ...updates };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "Failed to update account",
          options: { variant: "error" },
        }),
      );
      return rejectWithValue(error.message);
    }
  },
);

// Async thunk for deleting an account
export const deleteAccount = createAsyncThunk(
  "accounts/deleteAccount",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const accountRef = ref(db, `accounts/${id}`);
      await remove(accountRef);
      dispatch(
        enqueueSnackbar({
          message: "Account deleted successfully",
          options: { variant: "success" },
        }),
      );
      return id;
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "Failed to delete account",
          options: { variant: "error" },
        }),
      );
      return rejectWithValue(error.message);
    }
  },
);

// Async thunk for querying accounts (unchanged)
export const queryAccounts = createAsyncThunk(
  "accounts/queryAccounts",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const accountsRef = ref(db, "accounts");
      const snapshot = await get(accountsRef);
      const accounts = [];
      snapshot.forEach((childSnapshot) => {
        accounts.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      return accounts;
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "Failed to fetch accounts",
          options: { variant: "error" },
        }),
      );
      return rejectWithValue(error.message);
    }
  },
);

export const fetchUnlinkedAccounts = createAsyncThunk(
  "accounts/fetchUnlinked",
  async (_, { rejectWithValue }) => {
    try {
      const snapshot = await get(ref(db, "accounts"));
      const accounts = [];
      snapshot.forEach((childSnapshot) => {
        accounts.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      // Filter out linked accounts (you might need to adjust this logic based on your data structure)
      const unlinkedAccounts = accounts.filter((account) => !account.linked);
      return unlinkedAccounts;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const accountsSlice = createSlice({
  name: "accounts",
  initialState: {
    accounts: [],
    unlinkedAccounts: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Add Account
      .addCase(addAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.accounts.push(action.payload);
      })
      .addCase(addAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Edit Account
      .addCase(editAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editAccount.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.accounts.findIndex(
          (account) => account.id === action.payload.id,
        );
        if (index !== -1) {
          state.accounts[index] = {
            ...state.accounts[index],
            ...action.payload,
          };
        }
      })
      .addCase(editAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete Account
      .addCase(deleteAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.accounts = state.accounts.filter(
          (account) => account.id !== action.payload,
        );
      })
      .addCase(deleteAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Query Accounts
      .addCase(queryAccounts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(queryAccounts.fulfilled, (state, action) => {
        state.loading = false;
        state.accounts = action.payload;
      })
      .addCase(queryAccounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchUnlinkedAccounts.fulfilled, (state, action) => {
        state.unlinkedAccounts = action.payload;
        state.loading = false;
      });
  },
});

export default accountsSlice.reducer;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { fetchUserAccount, fetchUserCards } from "./../slices/user"; // Assuming you've initialized Firebase in a separate file named firebase.js

function ContactForm() {
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phoneNumber: user.phoneNumber || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted", formData);
    // Add further logic here (e.g., API call)
  };

  return (
    <Card
      sx={{
        maxWidth: "sm",
        width: { xs: `calc(100% - ${theme.spacing(4)})`, md: "100%" },
        m: 4,
      }}
    >
      <CardHeader title="Contact Information" />
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="firstName"
                name="firstName"
                label="First Name"
                fullWidth
                variant="outlined"
                value={formData.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="lastName"
                name="lastName"
                label="Last Name"
                fullWidth
                variant="outlined"
                value={formData.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                fullWidth
                variant="outlined"
                type="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                fullWidth
                variant="outlined"
                type="tel"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

function LinkedAccount() {
  const theme = useTheme();
  const { linkedAccount } = useSelector((state) => state.user);
  return (
    <Card
      sx={{
        maxWidth: "sm",
        width: { xs: `calc(100% - ${theme.spacing(4)})`, md: "100%" },
        m: 4,
      }}
    >
      <CardHeader title="Linked Account" />
      <CardContent>
        <Typography>
          {linkedAccount
            ? `${linkedAccount.houseNumber} ${linkedAccount.propertyAddress}`
            : "No Linked Account"}
        </Typography>
      </CardContent>
    </Card>
  );
}

function SavedCards() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { savedCards } = useSelector((state) => state.user);
  const squareCustomerId = user?.squareCustomerId;

  useEffect(() => {
    if (squareCustomerId) {
      dispatch(fetchUserCards(squareCustomerId));
    }
  }, [dispatch, squareCustomerId]);

  return (
    <Card
      sx={{
        maxWidth: "sm",
        width: { xs: `calc(100% - ${theme.spacing(4)})`, md: "100%" },
        m: 4,
      }}
    >
      <CardHeader title="Saved Cards" />
      <CardContent>
        {savedCards && savedCards.length > 0 ? (
          savedCards.map((card) => (
            <Card key={card.id} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6">
                  {card.cardBrand} **** {card.last4}
                </Typography>
                <Typography variant="body2">
                  Exp: {card.expMonth}/{card.expYear}
                </Typography>
                <Typography variant="body2">
                  Card Type: {card.cardType}
                </Typography>
                <Typography variant="body2">
                  Enabled: {card.enabled ? "Yes" : "No"}
                </Typography>
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography>No saved cards available.</Typography>
        )}
      </CardContent>
    </Card>
  );
}

function Subscriptions() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { subscriptions } = useSelector((state) => state.user);
  const squareCustomerId = user?.squareCustomerId;

  const formatDate = React.useCallback(
    (date) => {
      const dateParts = date.split("-");

      // Create the date object manually (Year, Month - 1, Day)
      const dateObj = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

      // Formatting the date to "Nov 1, 2024"
      const formattedDate = dateObj.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });

      return formattedDate;
    },
    [subscriptions],
  );

  return (
    <Card
      sx={{
        maxWidth: "sm",
        width: { xs: `calc(100% - ${theme.spacing(4)})`, md: "100%" },
        m: 4,
      }}
    >
      <CardHeader title="Subscriptions" />
      <CardContent>
        {subscriptions && subscriptions.length > 0 ? (
          subscriptions.map((subscription) => (
            <Card key={subscription.id} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6">
                  Status: {subscription.status}
                </Typography>
                <Typography variant="body2">
                  Start Date:{" "}
                  {subscription.startDate
                    ? formatDate(subscription.startDate)
                    : ""}
                </Typography>
                <Typography variant="body2">
                  Cancellation Date:{" "}
                  {subscription.canceledDate
                    ? formatDate(subscription.canceledDate)
                    : ""}
                </Typography>
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography>No active subscriptions .</Typography>
        )}
      </CardContent>
    </Card>
  );
}

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(fetchUserAccount(user?.uid));
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <ContactForm />
      <LinkedAccount />
      <Subscriptions />
      <SavedCards />
    </Box>
  );
};

export default ProfilePage;

import { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import SquarePaymentForm from "./SquarePaymentForm";
import Autopay from "./Autopay";

const PaymentDialog = ({ note, onClose, paymentAmount, amountDue }) => {
  const [autopay, setAutopay] = useState(false);
  const formatBalance = (cents) => {
    return (cents / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  useEffect(() => {
    const auto = note === "Monthly Autopay";
    setAutopay(auto);
  }, [note]);

  return (
    <Dialog open={Boolean(note)} onClose={onClose}>
      <DialogTitle sx={{ textAlign: "center" }}>
        {autopay
          ? "Monthly Autopay"
          : `Payment: ${formatBalance(paymentAmount)}`}
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="h3"
          sx={{ display: "inline-flex", alignItems: "center" }}
        ></Typography>
        {autopay ? (
          <Autopay />
        ) : (
          <SquarePaymentForm
            pay={paymentAmount}
            same={paymentAmount === amountDue}
            note={note}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PaymentDialog;

import logo from "./logo.svg";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";

import { store } from "./store";
import { AuthProvider } from "./providers/AuthProvider";
import Notifier from "./components/Notifier";
import { initializeAuthListener } from "./firebase_config";
import router from "./routes";

initializeAuthListener(store);

const defaultTheme = createTheme();

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
          <Notifier />
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;

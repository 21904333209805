// eslint-disable-next-line
/*global fetch */
import { uniqBy, find } from "lodash";
import React from "react";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { auth } from "./../firebase_config";

import { enqueueSnackbar } from "./../slices/notifications";

const EndSubscription = () => {
  // const { user } = useSelector((state) => state.auth); // Assuming you have a Firebase auth hook
  const currentUser = auth.currentUser;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { subscriptions } = useSelector((state) => state.user);
  const activeSubscription = React.useMemo(
    () => find(subscriptions, (s) => s.status === "ACTIVE"),
    [subscriptions],
  );
  const pendingSubscription = React.useMemo(
    () => find(subscriptions, (s) => s.status === "PENDING"),
    [subscriptions],
  );
  const subscriptionId = activeSubscription?.id || pendingSubscription?.id;

  const formatDate = React.useCallback(
    (date) => {
      const dateParts = date.split("-");

      // Create the date object manually (Year, Month - 1, Day)
      const dateObj = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

      // Formatting the date to "Nov 1, 2024"
      const formattedDate = dateObj.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });

      return formattedDate;
    },
    [subscriptions],
  );

  const handleCancel = async () => {
    setLoading(true);
    const response = await fetch(
      "https://us-central1-shelby-estates-hoa.cloudfunctions.net" +
        "/cancelSubscription",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await currentUser.getIdToken()}`, // Send Firebase token
        },
        body: JSON.stringify({
          subscriptionId,
        }),
      },
    );

    if (response.ok) {
      const result = await response.json();
      setLoading(false);
      dispatch(
        enqueueSnackbar({
          message: "Subscription Canceled Successfully",
          options: {
            variant: "success",
          },
        }),
      );
      console.log("Subscription cancel successful:", result);
    } else {
      const error = await response.json();
      setLoading(false);
      console.error("Subscription cancel failed", error);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: 300,
        textAlign: "center",
      }}
    >
      {pendingSubscription ? (
        <Typography>
          {" "}
          {`Autopay Scheduled.  Charges begin ${formatDate(pendingSubscription.startDate)}`}
        </Typography>
      ) : (
        <Typography>
          {activeSubscription?.canceledDate
            ? `Autopay Canceled.  Account remains active until ${formatDate(activeSubscription.canceledDate)}`
            : "Sumbit request to cancel Autopay"}
        </Typography>
      )}
      <br />

      <LoadingButton
        variant="contained"
        onClick={handleCancel}
        loading={loading}
        disabled={Boolean(activeSubscription?.canceledDate)}
      >
        Cancel Autopay
      </LoadingButton>
    </Box>
  );
};

const SubscriptionForm = () => {
  const { user } = useSelector((state) => state.auth); // Assuming you have a Firebase auth hook
  const currentUser = auth.currentUser;
  // const theme = useTheme();
  const [selectedCard, setSelectedCard] = React.useState("");
  const { savedCards, subscriptions } = useSelector((state) => state.user);

  const { loading, error, lastPaymentId } = useSelector(
    (state) => state.payments,
  );

  const uniqueCards = React.useMemo(
    () => uniqBy(savedCards, "fingerprint"),
    [savedCards],
  );

  const handleCardTokenize = async (token) => {
    if (selectedCard || (token.status === "OK" && user)) {
      // Pass the token to your backend to create a customer and store card details
      const response = await fetch(
        "https://us-central1-shelby-estates-hoa.cloudfunctions.net" +
          "/createCustomerAndSubscription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await currentUser.getIdToken()}`, // Send Firebase token
          },
          body: JSON.stringify({
            sourceId: selectedCard || token.token, // Token from Square
            uid: currentUser.uid,
            locationId: process.env.REACT_APP_SQUARE_LOCATION_ID,
          }),
        },
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Subscription setup successful:", result);
      } else {
        const error = await response.json();
        console.error("Subscription setup failed", error);
      }
    } else {
      console.error("Tokenization failed or user not authenticated");
    }
  };

  return find(
    subscriptions,
    (s) => s.status === "ACTIVE" || s.status === "PENDING",
  ) ? (
    <EndSubscription />
  ) : (
    <>
      {uniqueCards && uniqueCards.length > 0 && (
        <FormControl fullWidth sx={{ mb: 2, mt: 1 }}>
          <InputLabel id="saved-card-select-label">
            Select Saved Card
          </InputLabel>
          <Select
            labelId="saved-card-select-label"
            value={selectedCard}
            label="Select Saved Card"
            onChange={(e) => setSelectedCard(e.target.value)}
          >
            <MenuItem value="">Use New Payment Method</MenuItem>
            {uniqueCards.map((card) => (
              <MenuItem key={card.id} value={card.id}>
                {`${card.cardBrand} **** ${card.last4} (Exp: ${card.expMonth}/${card.expYear})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {selectedCard ? (
        <LoadingButton
          fullWidth
          loading={loading}
          variant="contained"
          color="primary"
          onClick={handleCardTokenize}
          sx={{
            p: 1.5,
            textTransform: "none",
            fontSize: "1rem",
          }}
        >
          Enable Autopay
        </LoadingButton>
      ) : (
        <PaymentForm
          applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
          locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
          cardTokenizeResponseReceived={handleCardTokenize}
        >
          <CreditCard>Enable Autopay</CreditCard>
        </PaymentForm>
      )}
    </>
  );
};

export default SubscriptionForm;

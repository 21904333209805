// eslint-disable-next-line
/*global fetch*/
import { uniqBy, reverse } from "lodash";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ref, set, get, update } from "firebase/database";

import { db, auth } from "./../firebase_config";

export const linkAccountToUser = createAsyncThunk(
  "user/linkAccount",
  async ({ userId, accountId }, { dispatch, rejectWithValue }) => {
    try {
      await set(ref(db, `userAccounts/${userId}`), accountId);
      await update(ref(db, `accounts/${accountId}`), {
        linked: true,
        [`linkedAccounts/${userId}`]: true,
      });
      dispatch(fetchUserAccount(userId));
      return { userId, accountId };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchUserAccount = createAsyncThunk(
  "user/fetchAccount",
  async (userId, { rejectWithValue }) => {
    try {
      const snapshot = await get(ref(db, `userAccounts/${userId}`));
      const accountId = snapshot.val();
      if (accountId) {
        const accountSnapshot = await get(ref(db, `accounts/${accountId}`));
        return { id: accountId, ...accountSnapshot.val() };
      }
      return null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchRecentTransactions = createAsyncThunk(
  "user/fetchTransactions",
  async (userId, { rejectWithValue }) => {
    try {
      const snapshot = await get(ref(db, `transactions/${userId}`));
      const transactions = [];
      snapshot.forEach((childSnapshot) => {
        transactions.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      return reverse(transactions);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchUserCards = createAsyncThunk(
  "payment/fetchUserCards",
  async (squareCustomerId, { rejectWithValue }) => {
    try {
      const user = auth.currentUser;
      const idToken = await user.getIdToken();
      const response = await fetch(
        "https://us-central1-shelby-estates-hoa.cloudfunctions.net/fetchCustomerCards",
        {
          method: "POST", // Changed method to POST as it should not be GET when sending a body
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`, // Uncomment and provide idToken if required
          },
          body: JSON.stringify({
            customerId: squareCustomerId,
          }),
        },
      );
      const data = await response.json();
      if (response.ok && data.success) {
        const cards = uniqBy(data.cards, "fingerprint");
        const subscriptions = data.subscriptions;
        return { cards, subscriptions };
      } else {
        return rejectWithValue(data.error || "Error Fetching cards");
      }
    } catch (error) {
      console.error("Error in fetchCustomerCards:", error);
      return rejectWithValue(
        "An unexpected error occurred while retrieving saved cards",
      );
    }
  },
);

export const fetchTransactions = createAsyncThunk(
  "payments/fetchTransactions",
  async (_, { rejectWithValue }) => {
    try {
      const user = auth.currentUser;
      const idToken = await user.getIdToken();
      const response = await fetch(
        "https://us-central1-shelby-estates-hoa.cloudfunctions.net/fetchTransactions",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        },
      );
      const data = await response.json();
      if (response.ok) {
        return data.customerPayments;
      } else {
        return rejectWithValue(data.error || "Error Fetching cards");
      }
    } catch (error) {
      console.error("Error in fetchTransactions:", error);
      return rejectWithValue(
        "An unexpected error occurred while fetching transactions",
      );
    }
  },
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    linkedAccount: null,
    savedCards: null,
    subscriptions: null,
    recentTransactions: [],
    transactions: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(linkAccountToUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchUserAccount.fulfilled, (state, action) => {
        state.linkedAccount = action.payload;
        state.loading = false;
      })
      .addCase(fetchRecentTransactions.fulfilled, (state, action) => {
        state.recentTransactions = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserCards.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchUserCards.fulfilled, (state, action) => {
        state.savedCards = action.payload?.cards;
        state.subscriptions = action.payload?.subscriptions;
        state.loading = false;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload;
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.loading = true;
          state.error = null;
        },
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;
          state.error = action.payload;
        },
      );
  },
});

export default userSlice.reducer;
